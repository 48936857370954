<template>
    <h3>{{ $t('newsletter_title') }}</h3>

    <p class="mb-0">
        {{ $t('newsletter_description') }}
    </p>

    <p>
        {{ $t('newsletter_cta') }}
    </p>

    <div class="mb-3">
        <TextField
            id="email"
            additional-class="w-75"
            :placeholder="$t('Email address')"
            :model="email"
            @update:model="email = $event">
        </TextField>
    </div>

    <div class="form-check mt-3 checkbox-container mb-5 mb-lg-3">
        <input v-model="agreedToTerms" class="form-check-input" type="checkbox" id="termsCheckbox" required>
        <label class="form-check-label" for="termsCheckbox">
            <p v-html="agreementText"/>
        </label>
    </div>

    <button @click="subscribe()" class="btn btn-primary btn-lg" :disabled="!agreedToTerms">{{ $t('signup_button_text') }}</button>
</template>

<script setup>
import {useSettingsStore} from "../../../stores/SettingsStore.js";

const { t } = useI18n();

const emit = defineEmits(['newsletter-subscribed']);

import {ref} from "vue";
import api from '~/apis/user.js';
import TextField from "../../Fields/TextField.vue";

import {useI18n} from "vue-i18n";
import {useAlertStore} from "../../../stores/AlertStore.js";
import {useLocaleStore} from "../../../stores/LocaleStore.js";

const email = ref('');
const agreedToTerms = ref(false);

const agreementText = t('newsletter_agreement', {
    policy: `<a href="${useSettingsStore().translatable('privacy_policy_url')}">${t('Privacy Policy')}</a>`
});
const subscribe = async () => {
    const response = await api.subscribeToNewsletter(email.value);
    if (!response) {
        return;
    }
    emit('newsletter-subscribed', email.value);
};

</script>
