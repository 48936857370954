<template>
    <h3>{{ $t('newsletter_title_2', {name: email}) }}</h3>

    <p >
        {{ $t('newsletter_description_2') }}
    </p>

    <button @click="close()" class="btn btn-primary btn-lg">{{ $t('Continue Shopping') }}</button>
</template>


<script setup>
const props = defineProps({
    email: String
});

const emit = defineEmits(['close']);

const close = () => {
    emit('close');
}
</script>

