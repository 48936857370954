<script setup>
import { useSettingsStore } from "../stores/SettingsStore.js";

const props = defineProps({
    name: String,
    strict: {
        type: Boolean,
        default: false
    }
});

</script>

<template>
    <div v-if="useSettingsStore().hasAccessToComponent(props.name, strict)">
        <slot></slot>
    </div>
</template>

<style scoped>
/**/
</style>
