<template>
    <form v-if="useLocaleStore().ship_countries">
        <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="dropdownShipCountry" data-bs-toggle="dropdown" aria-expanded="false">
            <template v-if="useLocaleStore().ship_country.icon">
                <img class="size-16" width="16" height="16" :src="`/img/flags/${useLocaleStore().ship_country.code.toLowerCase()}.svg`" :alt="useLocaleStore().ship_country.code">
                {{$t('Ship To')}}
            </template>
            <template v-else>
                {{ useLocaleStore().ship_country.name }}
            </template>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownShipCountry">
            <a v-for="country in useLocaleStore().ship_countries" class="dropdown-item d-flex align-items-center" href="#" @click.prevent="setShipCountry(country.code)">
                {{ country.name }}
            </a>
        </div>
    </form>
</template>


<script setup>
import {useLocaleStore} from "../stores/LocaleStore.js";


function setShipCountry(code) {
    if (code === useLocaleStore().ship_country.code) {
        return;
    }
    useLocaleStore().changeCountry(code).then((response) => {
        window.location.reload();
    });
}
</script>






