<script setup>
import ModalContent from "./ModalContent.vue";

const modals = [
    'AlertModal',
    'WatchlistCreateAccountModal',
    'ProductNotifyModal',
    'ProductAddedToCart',
    'Seo',
    'PromoModal'
]
</script>

<template>
    <ModalContent v-for="modal in modals" :id="modal"/>
</template>
