<template>
    <BaseField :col="col" :id="id" :form-group="formGroup" :required="required" :show-error-message="showErrorMessage">
        <div class="form-check">
            <input
                ref="baseInput"
                class="form-check-input"
                type="checkbox"
                :id="id"
                :checked="model"
                :required="required"
                :disabled="disabled"
                @change="onChange($event)"
            />

            <label class="form-check-label" :for="id">
                {{ $t(label) }}
            </label>
        </div>
    </BaseField>
</template>

<script>
import BaseField from "./BaseField.vue";

export default {
    name: 'CheckBox',
    components: { BaseField },
    props: {
        model: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    extends: BaseField,
    methods: {
        onChange(event) {
            const checked = event.target.checked;
            this.emitUpdate(checked);
        },

        focus() {
            this.$refs.baseInput.focus();
        }
    }
};
</script>
