<template>
    <Modal
        id="NewsletterSubscription"
        ref="modalRef"
        modal-dialog-class="modal-dialog-centered modal-lg newsletter-modal overflow-hidden"
        modal-body-class="h-100 ps-0"
        :show-header="false"
        :show-footer="false"
    >
        <div class="container-fluid h-100 ps-lg-0">
            <div class="row h-100" >
                <div class="col-12 col-lg-5 offset-lg-1 ps-lg-0 h-100 d-flex align-items-center">
                    <div class="w-100 d-lg-flex flex-column">
                        <Unsubscribed v-if="!isSubscribed" @newsletter-subscribed="subscriptionCompleted"/>
                        <Subscribed v-else :email="email" @close="close()"/>
                    </div>
                </div>
                <div class="col-12 col-lg-7 h-100">
                    <img src="@img/newsletter-coins.png" alt="Coins" class="img-fluid modal-coin-img position-absolute pb-0 pe-0" style="">
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import Modal from "../Modal.vue";
import Unsubscribed from "./Unsubscribed.vue";
import Subscribed from "./Subscribed.vue";
import {ref} from "vue";
import {useModal} from "../../../stores/ModalStore.js";

const name = 'NewsletterSubscription';

const modalRef = ref(null);
const isSubscribed = ref(false);
const email = ref('');

const close = () => {
    useModal().closeModal(name);
}

const subscriptionCompleted = (emailValue) => {
    isSubscribed.value = true;
    email.value = emailValue;
};

</script>
