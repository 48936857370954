<template>
    <div class="search-container">
        <div>
            <a v-show="text" href="#" class="close-search" @click.prevent="resetSearch">&times;</a>
            <a href="#" class="search-icon" @click.prevent="advancedSearch(text)">
                <img src="@img/i-search.svg" height="20" width="20" alt="search" />
            </a>
            <input v-model="text" class="form-control form-search"
                   @keydown.down="hoverChange(1)"
                   @keydown.up="hoverChange(-1)"
                   @keyup.enter.prevent="openProduct(hoveredIndex)"
                   @keyup.esc.prevent="escapePress()"
                   @focusin="isFocused=true"
                   @focusout="focusOut()"
                   :placeholder="$t('Product Search')"
            >
        </div>

        <div class="search-results shadow" v-show="products.length > 0 && text.length > 0 && isFocused" @mouseover="mouseOnResults=true" @mouseout="mouseOnResults=false">
            <div v-for="(product, index) in products" class="row py-2 m-0 border-bottom search-result"
                 @mouseover="hoveredIndex = index"
                 @mouseout="hoveredIndex = null"
                 @click="openProduct(index)"
                 :class="{'selected': hoveredIndex === index}"
            >
                <div class="col-auto position-relative">
                    <span class="product-search-badge" :class="getBadgeColorClass(product)" :title="getSentenceCase(product.availability.code)">&nbsp;</span>
                    <img v-if="product.images.small" class="size-40" :src="product.images.small" :alt="product.name">
                    <span v-else class="product-no-image-circle size-40 d-block"></span>
                </div>
                <div class="col ps-0">
                    <div class="mb-2">{{ product.name }}</div>
                    <small v-if="product.state === productStateActive && product.availability.code !== 'out_of_stock' && product.gross_price > 0" class="fw-bold" v-html="$formatter.price(product.gross_price)"></small>
                    <small v-else class="text-danger fw-bold">Out of stock</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        productStateActive() {
            return 0;
        }
    },
    data() {
        return {
            text: useRoute().query.term,
            products: [],
            isFocused: false,
            hoveredIndex: null,
            mouseOnResults: false,
            responseIndex: 0 // responseIndex purpose is that we want to only parse the last response
        };
    },
    mounted() {
        this.addStickyEventListeners();
        window.addEventListener('load', this.applySticky);

        this.$watch(
            () => this.$route.query?.term,
            (e) => {
                this.text = e || '';
            }
        );
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.applySticky);
    },
    watch: {
        text(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.search();
            }
        }
    },
    methods: {
        resetSearch () {
            const route = useRoute();
            const router = useRouter();

            const newQuery = { ...route.query };
            delete newQuery['term'];

            router.push({ path: route.path, query: newQuery });
            this.text = '';
            this.advancedSearch('');
        },
        addStickyEventListeners() {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.applySticky);
        },
        handleScroll() {
            if (window.innerWidth > 576) {
                return;
            }

            const headerHeight = document.querySelector('header').offsetHeight;

            if (window.scrollY > headerHeight) {
                this.sticky = true;
                this.$el.parentNode.classList.add('sticky-search-bar');
            } else {
                this.sticky = false;
                this.$el.parentNode.classList.remove('sticky-search-bar');
            }
        },
        applySticky() {
            if (window.innerWidth <= 576) {
                this.handleScroll();
            } else {
                this.removeSticky();
            }
        },
        removeSticky() {
            this.sticky = false;
            this.$el.parentNode.classList.remove('sticky-search-bar');
        },
        async search() {
            if (!this.text) {
                return;
            }
            this.responseIndex++;
            let products = await getFromClient('products/search', {params: {term: this.text}});

            this.parseResults(products, this.responseIndex);
        },
        parseResults(data, responseIndex) {
            // Only show if it is the last request (don't override previous requests on next ones)
            if (this.responseIndex === responseIndex) {
                this.products = data; //here was a bug, data/data is correct

            }
            // if hover is on result 10 and new search query has 4 results, reset hover
            if (this.products.length - 1 < this.hoveredIndex) {
                this.hoveredIndex = null;
            }
        },
        focusOut() {
            if (!this.mouseOnResults) {
                this.isFocused = false;
            }
        },
        openProduct(productIndex) {
            // Use callback instead of default behaviour
            if (this.clickCallback) {
                let callback = this.clickCallback.split('.');
                if ('number' === typeof(productIndex)) {
                    let fn = window;
                    for (let ind in callback) {
                        fn = fn[callback[ind]];
                    }
                    fn(this.products[productIndex].id);
                    this.text = '';
                }
                return;
            }

            this.isFocused = false;
            // If no result is selected, simply search for the text
            if (productIndex === null) {
                this.advancedSearch(this.text);
                return;
            }
            this.$router.push(this.products[productIndex].url);
        },
        async advancedSearch(term) {
            const store = useCatalogStore();
            if (store.paginator) {
                store.paginator.current_page = 1;
            }
            const page = useRouterStore().getCurrentPage();
            if (
                page?.name !== 'catalog' &&
                !this.$route.name?.startsWith('search') &&
                store.catalogResource
            ) {
                store.catalogResource.filters_selected = [];
            }
            await store.fetchCatalog(term);
            this.$router.push(store.catalog.url);
        },
        /**
         * @param direction -1 => up, 1 => down
         */
        hoverChange(direction) {
            // If nothing was selected, select first if pressed down, select last if pressed up
            if (this.hoveredIndex === null && this.products.length > 0) {
                if (direction === 1) {
                    this.hoveredIndex = 0;
                } else {
                    this.hoveredIndex = this.products.length - 1;
                }
                return;
            }
            // If next/previous exists, move to it
            if (typeof this.products[this.hoveredIndex + direction] !== 'undefined') {
                this.hoveredIndex += direction;
            } else {
                this.hoveredIndex = null;
            }
        },
        escapePress() {
            if (this.hoveredIndex === null) {
                this.text = '';
            } else {
                this.hoveredIndex = null
            }
        },
        getSentenceCase(text) {
            if (text) {
                return text.replace(/(^\w)/, x => x.toUpperCase()).replace(/_/g, ' ');
            } else {
                return text;
            }
        },
        getBadgeColorClass(product) {
            if (product.state !== this.productStateActive || product.availability.code === 'out_of_stock') {
                return 'bg-red';
            }
            if (product.available_quantity > 0) {
                if (product.limited_mintage) {
                    return 'bg-orange';
                } else {
                    return 'bg-green';
                }
            }

            return 'bg-green';
        }
    },
};
</script>

<style>
.sticky-search-bar {
    position: fixed;
    top: 0;
    z-index: 1000;
    padding-right: 24px;
    padding-top: 10px;
}
</style>
