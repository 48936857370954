<template>
    <form v-if="localeStore.languages">
        <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="dropdownLang" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="size-16" width="16" height="16" :src="`/img/flags/${localeStore.getLanguage()}.svg`" :alt="localeStore.getLanguage()">
            {{$t('Language')}}
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownLang">
            <a v-for="language in localeStore.languages" class="dropdown-item d-flex align-items-center" href="#" @click.prevent="changeLocale(language.prefix)">
                <img class="size-16" :src="`/img/flags/${language.prefix}.svg`" loading="lazy" :alt="language.prefix">&nbsp;{{ language.native_name }}
            </a>
        </div>
    </form>
</template>

<script setup>
import {useLocaleStore} from '../stores/LocaleStore.js';
const { setLocale } = useI18n();

const localeStore = useLocaleStore();

async function changeLocale(prefix) {
    await setLocale(prefix);
    await localeStore.changeLanguage(prefix);
}
</script>






