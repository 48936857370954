<template>
    <Modal ref="SeoModal" id="Seo" :title="seo.url" modal-dialog-class="modal-dialog-centered" @submit="saveMeta" modal-header-class="bg-white">
        <div class="mb-3">
            <TextArea
                id="title"
                label="Title"
                :rows="3"
                :required="false"
                recommendation="recommended under 70 characters"
                :model="seo.title"
                @update:model="seo.title = $event"
            />
        </div>
        <div class="mb-3">
            <TextArea
                id="description"
                label="Description"
                :required="false"
                recommendation="recommended 60-160 characters"
                :rows="3"
                :model="seo.description"
                @update:model="seo.description = $event"
            />
        </div>
        <div class="mb-3">
            <TextArea
                id="keywords"
                label="Keywords"
                :required="false"
                :rows="3"
                :model="seo.keywords"
                @update:model="seo.keywords = $event"
            />
        </div>
        <div class="mb-3">
            <CheckBox
                id="noindex"
                label="noindex"
                :model="seo.noindex"
                @update:model="seo.noindex = $event"
            />
            <CheckBox
                id="nofollow"
                label="nofollow"
                :model="seo.nofollow"
                @update:model="seo.nofollow = $event"
            />
        </div>
    </Modal>
</template>

<script setup>
import { ref } from 'vue';
import TextArea from "../Fields/TextArea.vue";
import CheckBox from "../Fields/Checkbox.vue";
import api from "../../apis/seo.js";
import Modal from "./Modal.vue";

const props = defineProps({
    content_id: Number
});

const seo = ref({
    title: '',
    description: '',
    keywords: '',
    noindex: false,
    nofollow: false,
    content_id: 0,
    url: window.location.pathname
});

api.index(seo.value.url, props.content_id).then((response) => {
    const data = response.data;

    seo.value.title = data.seo.title;
    seo.value.description = data.seo.description;
    seo.value.keywords = data.seo.keywords;
    seo.value.content_id = data.seo.content_id;
    seo.value.noindex = data.seo.noindex > 0;
    seo.value.nofollow = data.seo.nofollow > 0;
});

const saveMeta = async () => {
    const response = await api.update(seo.value);

    if (!response?.success) {
        return;
    }
    applySeoChanges(response.data);
    useModal().closeModal('Seo');
};

const applySeoChanges = (newSeo) => {
    if (newSeo.title) {
        document.title = newSeo.title;
    }

    const setMetaTag = (name, content) => {
        let meta = document.querySelector(`meta[name="${name}"]`);
        if (meta) {
            meta.setAttribute('content', content);
        } else {
            meta = document.createElement('meta');
            meta.setAttribute('name', name);
            meta.setAttribute('content', content);
            document.head.appendChild(meta);
        }
    };

    if (newSeo.description) {
        setMetaTag('description', newSeo.description);
    }

    if (newSeo.keywords) {
        setMetaTag('keywords', newSeo.keywords);
    }

    let robotsContent = '';
    if (newSeo.noindex) {
        robotsContent += 'noindex, ';
    }
    if (newSeo.nofollow) {
        robotsContent += 'nofollow, ';
    }
    robotsContent = robotsContent.replace(/, $/, '');

    if (robotsContent) {
        setMetaTag('robots', robotsContent);
    } else {
        const robotsMeta = document.querySelector('meta[name="robots"]');
        if (robotsMeta) {
            document.head.removeChild(robotsMeta);
        }
    }
};
</script>
