<template>
    <ul class="list-unstyled">
        <li class="mb-2" v-for="language in useLocaleStore().languages">
            <a :href="url(language.prefix)"
               :title="language.native_name"
               @click="(event) => { event.preventDefault(); changeLanguage(language.prefix); }">
                <img :src="`/img/flags/${language.prefix}.svg`"
                     :alt="language.prefix"
                     class="size-16 me-1"
                     loading="lazy">
                {{ language.native_name }}
            </a>
        </li>
    </ul>
</template>

<script setup>
import { useLocaleStore } from '~/stores/LocaleStore';
const { setLocale } = useI18n();

const changeLanguage = async (language) => {
    await useLocaleStore().changeLanguage(language);
    await setLocale(language)
}

</script>

<script>
export default {
    computed: {
        url() {
            return prefix => '/' + prefix + '/';
        },
    }
};
</script>
