<template>
    <div class="text-center w-100 pt-3 d-flex justify-content-center justify-content-lg-start">
        <div style="max-width:242px;color-scheme: normal;">
            <div
                id="trustbox"
                ref="trustbox"
                class="trustpilot-widget"
                :data-locale="useLocaleStore().getLanguage()"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="650ec0707a3cc2a49edb3b18"
                data-style-height="110px"
                data-style-width="100%"
                data-theme="dark">
                <a href="https://trustpilot.com/review/stonexbullion.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
        </div>
    </div>
</template>

<script setup>
useHead({
    script: [
        {
            async: true,
            src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
        }],
});
</script>

<script>
export default {
    mounted() {
        const trustbox = document.getElementById('trustbox');
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustbox);
        }
    },
};
</script>
