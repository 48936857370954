export default {
    index(url, content_id) {
        return useClientFetch('seo/?url=' + url + '&content_id=' + content_id);
    },
    update(body) {
        return useClientFetch('seo', {
            method: 'POST',
            body
        });
    },
};
